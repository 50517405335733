export const randomString = (length = 10, options = {}) => {
  const charset =
    options.charset ||
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charsetLength = charset.length;

  if (!Number.isInteger(length) || length <= 0) {
    throw new Error("Invalid length specified for randomString");
  }

  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charsetLength);
    result += charset[randomIndex];
  }

  return result;
};
export const futch = (
  url,
  opts = {},
  disableResponseHeaders = false,
  onProgress = () => null
) => {
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method || "get", url);
    for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = (e) =>
      disableResponseHeaders
        ? res({ data: e.target.responseText })
        : res({
            data: e.target.responseText,
            id: xhr.getResponseHeader("x-amz-version-id"),
          });
    xhr.onerror = rej;
    // xhr.onreadystatechange = () => {
    //   if (xhr.readyState === xhr.HEADERS_RECEIVED) {
    //     const myHeader = xhr.getResponseHeader("x-amz-version-id");
    //     console.log(myHeader)
    //     res({data: e.target.responseText, id: myHeader})
    //   }
    // };
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body);
  });
};

export function capitalizeFirstLetter(inputString) {
  if (inputString.length === 0) {
    return inputString; // Return the original string if it's empty
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}


export const getBlockNotesBlockText = (blok) => {
  let text = "";
  if (blok?.content?.length) {
    blok?.content?.map((paragraph) => {
      text += paragraph.text;
    });
  }
  return text;
};

export function chunkArray(array, chunkSize) {
  const result = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}

export const findBounds = (points) => {
  var n = points.length;
  if (n === 0) {
    return [];
  }
  var d = points[0].length;
  var lo = points[0].slice();
  var hi = points[0].slice();
  for (var i = 1; i < n; ++i) {
    var p = points[i];
    for (var j = 0; j < d; ++j) {
      var x = p[j];
      lo[j] = Math.min(lo[j], x);
      hi[j] = Math.max(hi[j], x);
    }
  }
  return [lo, hi];
};
