import {
    Flex,
    ListIcon,
    ListItem,
    Stack,
    StackDivider,
    Text,
    useColorModeValue,
    List,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { motion, useAnimation } from "framer-motion";
  import { MdCheckCircle } from "react-icons/md";
import { parseISO } from "date-fns";
import Card from "../../card/Card";
  
  export default function DocumentViewerProperties({
    onEdit,
    data,
    isLoading,
    ...rest
  }) {
    const [animateOnFirstRender, setAnimateOnFirstRender] = useState(true);
  
    const controls = useAnimation();
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const bg = useColorModeValue("white", "navy.700");
  
    useEffect(() => {
      if (animateOnFirstRender) {
        controls.start({
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.7,
          },
        });
        setAnimateOnFirstRender(false); // Disable animation after first render
      }
    }, [animateOnFirstRender, controls]);
    return (
      <motion.div
        initial={{ opacity: 0, x: -30 }} // Initial animation properties
        animate={controls} // Pass the animation controls
      >
        <Card
          bg="white"
          minW={"300px"}
        >
          <Stack spacing="5" divider={<StackDivider />}>
            <Stack
              justify="space-between"
              alignItems={"center"}
              direction={{ base: "column", sm: "row" }}
              spacing="5"
            >
              <Stack spacing="1">
                <Text
                  textStyle={{ base: "lg", xl: "md", "2xl": "lg" }}
                  fontWeight="bold"
                >
                  Properties
                </Text>
              </Stack>
            </Stack>
            <Flex flexDir={"column"}>
              <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
                Effective Date
              </Text>
              <Text color={textColorPrimary} fontWeight="500" fontSize="md">
                {data?.effective_date?parseISO(data?.effective_date):"No Date"}
              </Text>
            </Flex>
            <Flex flexDir={"column"}>
              <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
                Published By
              </Text>
              <Text color={textColorPrimary} fontWeight="500" fontSize="md">
                {data?.owner}
              </Text>
            </Flex>
            {data?.authors?.length ? (
              <Flex flexDir={"column"}>
                <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
                  Authors
                </Text>
                <List spacing={1}>
                  {data?.authors?.map((author, index) => (
                    <ListItem key={index}>
                      {author.first_name + " " + author.last_name}
                    </ListItem>
                  ))}
                </List>
              </Flex>
            ) : null}
            {data?.reviewers?.length ? (
              <Flex flexDir={"column"}>
                <Flex justifyContent={"space-between"}>
                  <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
                    Reviewers
                  </Text>
                </Flex>
                <List spacing={1}>
                  {data?.reviewers?.map((reviewer, index) => (
                    <ListItem key={index}>
                      {reviewer.first_name + " " + reviewer.last_name}
                      {reviewer?.acknowledged && (
                        <ListIcon ml="1" as={MdCheckCircle} color={"green.500"} />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Flex>
            ) : null}
            {data?.approvers?.length ? (
              <Flex flexDir={"column"}>
                <Flex justifyContent={"space-between"}>
                  <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
                    Approvars
                  </Text>
                </Flex>
                <List size={{ base: "md", xl: "sm", "2xl": "md" }} spacing={1}>
                  {data?.approvers?.map((approvar, index) => (
                    <ListItem key={index}>
                      {approvar.first_name + " " + approvar.last_name}
                      {approvar?.acknowledged && (
                        <ListIcon ml="1" as={MdCheckCircle} color={"green.500"} />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Flex>
            ) : null}
          </Stack>
        </Card>
      </motion.div>
    );
  }
  