import React, { useRef } from "react";
import { ScopeBlock, insertScope } from "../../blocknotes/ScopeLabel";
import { RoleBlock, insertRole } from "../../blocknotes/RolesBlock";
import { ControlBlock, insertControl } from "../../blocknotes/ControlBlock";
import { MermaidBlock, insertMermaid } from "../../blocknotes/MermaidBlock";
import { CodeBlock, insertCode } from "../../blocknotes/CodeBlock";
import {
  BlockNoteView,
  HyperlinkToolbarPositioner,
  SideMenuPositioner,
  SlashMenuPositioner,
  getDefaultReactSlashMenuItems,
  useBlockNote,
} from "@blocknote/react";
import { defaultBlockSchema } from "@blocknote/core";
import "@blocknote/core/style.css";
import {
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import Card from "../../card/Card";
import DocumentViewerProperties from "./DocumentViewerProperties";
import CustomButton from "../../custom-styled-components/CustomButton";

export default function DocumentView({
  editorData,
  title,
  propertiesData,
  onEditorReady = () => null,
  ...props
}) {
  const customSchema = {
    // Adds all default blocks.
    ...defaultBlockSchema,
    // Adds the custom image block.
    scope: ScopeBlock,
    role: RoleBlock,
    control: ControlBlock,
    mermaid: MermaidBlock,
    procode: CodeBlock,
  };
  const editor = useBlockNote({
    // Tells BlockNote which blocks to use.
    blockSchema: customSchema,
    slashMenuItems: [
      ...getDefaultReactSlashMenuItems(customSchema),
      insertScope,
      insertRole,
      insertControl,
      insertMermaid,
      insertCode,
    ],
    editable: false,
    initialContent: editorData,
    onEditorReady: onEditorReady
  });
  return (
    <Flex spacing={"4"} flex="3" p="6" overflow={"auto"} h="100vh" w="full">
      <Flex
        position={"relative"}
        flexDir={"row"}
        justifyContent={"center"}
        w="full"
        h="max-content"
      >
        <Flex flex={"1"}></Flex>
        <Card position="relative" width={"21cm"} h="max-content" minH="29.7cm">
          <Heading
            mt="3"
            ml="50px"
            mr="50px"
            _empty={{
              content: "'Test Data'",
            }}
            outline={"none"}
            placeholder="Untitled"
          >
            {title}
          </Heading>
          <BlockNoteView editor={editor} theme={"light"} />
        </Card>
        <Flex flex={"1"} paddingLeft={"6"}>
          <Stack right={0} h="full" maxW={"30%"} spacing={"3"}>
            <DocumentViewerProperties data={propertiesData} />
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}
