import { createReactBlockSpec } from "@blocknote/react";
import { defaultProps } from "@blocknote/core";
import {
  ChakraProvider,
  Flex,
  Stack,
} from "@chakra-ui/react";
import theme from "../../theme/theme";
import Mermaid from "./Mermaid";
import { MdNote } from "react-icons/md";
import ReactCodeMirror from "@uiw/react-codemirror";
import { langs } from "@uiw/codemirror-extensions-langs";

const TYPE = "mermaid";

const MermaidComponent =({ block, editor }) => {
  const { data } = block?.props;
  const onInputChange = (val, viewUpdate) => {
    editor.updateBlock(block, {
      props: { ...block.props, data: val },
    });
  };
  return (
    <ChakraProvider theme={theme}>
      <Stack
        borderColor={"secondaryGray.200"}
        p="2"
        borderWidth={editor?.isEditable ? "1px" : "0px"}
        borderRadius={"5px"}
        align={"center"}
      >
        {editor?.isEditable && (
          <ReactCodeMirror
            placeholder={"Write your mermaid code here..."}
            style={{ width: "100%", resize: "vertical" }}
            extensions={[langs.mermaid()]}
            basicSetup={{
              lineNumbers: false,
              foldGutter: false,
              syntaxHighlighting: true,
            }}
            theme={"dark"}
            value={data}
            width="100%"
            height="200px"
            onChange={onInputChange}
          />
        )}
        <Flex justify={"center"} w="full">
          <Mermaid name={block?.id} chart={data?.trim()} />
        </Flex>
      </Stack>
    </ChakraProvider>
  );
};

export const MermaidBlock = createReactBlockSpec({
  type: TYPE,
  propSchema: {
    ...defaultProps,
    data: "",
  },
  containsInlineContent: false,
  render: MermaidComponent,
});

export const insertMermaid = {
  name: "Mermaid",
  group: "Other",
  execute: (editor) => {
    const block = editor.getTextCursorPosition().block;
    const blockIsEmpty = block.content.length === 0;

    // Updates current block to an Alert if it's empty, otherwise inserts a new
    // one below
    if (blockIsEmpty) {
      editor.updateBlock(block, { type: TYPE });
    } else {
      editor.insertBlocks(
        [
          {
            type: TYPE,
          },
        ],
        editor.getTextCursorPosition().block,
        "after"
      );
    }
  },
  aliases: ["mermaid"],
  icon: <MdNote />,
  hint: "Insert a Mermaid chart",
};
