// Chakra imports
import {
    Flex,
    Icon,
    Select,
    Text,
    useColorModeValue,
    SimpleGrid,
  } from "@chakra-ui/react";
  // Custom components
  import React from "react";
  // Assets
  import {
    MdAcUnit,
    MdWifi,
    MdThermostat,
    MdOutlineLightbulb,
    MdOutlineLocationOn,
    MdCallReceived,
  } from "react-icons/md";
import Card from "../card/Card";
import Controller from "./components/Controller";
import { RiShareLine } from "react-icons/ri";
import { BiUserPlus } from "react-icons/bi";
import { AiOutlineDropbox } from "react-icons/ai";
import { IoIosSend } from "react-icons/io";
import { DROPZONE_COLOR, REQUEST_COLOR, RESPONSE_COLOR, SEND_COLOR } from "../../constants/constants";
  
  export default function CircularProgress() {
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    return (
      <Card p='30px' h="full">
        <Text mb="10px" fontSize={{base: 'lg', "xl": "sm", "2xl": "lg"}} lineHeight='100%' color={textColor} fontWeight='bold'>
          Data Tools
        </Text>
  
        <SimpleGrid columns='2' gap='20px' h="full" justifyContent={"center"} alignItems={"center"}>
          <Controller
            initial={true}
            activeColor={SEND_COLOR}
            text='Send Data'
            link="/sypher/send/new"
            icon={IoIosSend}
          />
          <Controller
            initial={true}
            text='Request Data'
            link="/sypher/request/new"
            activeColor={REQUEST_COLOR}
            icon={BiUserPlus}
          />
          <Controller
            initial={true}
            text='Read Responses'
            link="/sypher/request/responses"
            activeColor={RESPONSE_COLOR}
            icon={MdCallReceived}
          />
          <Controller
            initial={false}
            text='Create Dropzone'
            link="/sypher/dropzones/new"
            activeColor={DROPZONE_COLOR}
            icon={AiOutlineDropbox}
          />
        </SimpleGrid>
      </Card>
    );
  }
  


// import React from "react";
// import Card from "@/components/card/Card";
// import { Box, Button, Flex, HStack, Stack, Text, useColorModeValue } from "@chakra-ui/react";
// import { HiExternalLink } from "react-icons/hi";

// export default function Sypher() {
//   let mainText = useColorModeValue("navy.700", "white");
//   let secondaryText = useColorModeValue("gray.500", "white");
//   return (
//     <Card w="" minH={{ base: "310px", md: "100%" }} bgColor={"#fff"}>
//       <Stack>
//         <Stack mb="25px" align={"center"} direction={"row"} justifyContent={"space-between"}>
//           <Text color={mainText} fontSize={"2xl"} fontWeight={"semibold"}>Syhper</Text>
//           <Button colorScheme="brand" size={"sm"} rightIcon={<HiExternalLink />}>
//               Read More
//           </Button>
//         </Stack>
//         <Stack direction={"column"}>
//           <Card position="relative" w="full" p="2" pl="5px" borderLeftWidth="5px" borderTopLeftRadius="0px" borderBottomLeftRadius="0px" px="5" overflow="hidden" transition="all .3s">
//             <Stack direction={"row"} spacing={0} justifyContent={"space-between"} align={"center"}>
//               <Text fontWeight={"semibold"} color={secondaryText}>Total Sends</Text>
//               <Text color={"brand.500"} fontWeight={"bold"}>20</Text>
//             </Stack>
//           </Card>
//           <Card w="full" p="2" px="5"  pl="5px" borderLeftWidth="5px" borderTopLeftRadius="0px" borderBottomLeftRadius="0px">
//             <Stack direction={"row"} spacing={0} justifyContent={"space-between"} align={"center"}>
//               <Text fontWeight={"semibold"} color={secondaryText}>Total Requests</Text>
//               <Text color={"brand.500"} fontWeight={"bold"}>20</Text>
//             </Stack>
//           </Card>
//           <Card w="full" p="2" px="5">
//             <Stack direction={"row"} spacing={0} justifyContent={"space-between"} align={"center"}>
//               <Text fontWeight={"semibold"} color={secondaryText}>Total Dropzones</Text>
//               <Text color={"brand.500"} fontWeight={"bold"}>20</Text>
//             </Stack>
//           </Card>
//         </Stack>
//       </Stack>
//     </Card>
//   );
// }

