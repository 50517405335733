import { createReactBlockSpec } from "@blocknote/react";
import { defaultProps } from "@blocknote/core";
import { MdCode } from "react-icons/md";
import ReactCodeMirror from "@uiw/react-codemirror";
import { langs } from "@uiw/codemirror-extensions-langs";

const TYPE = "procode";

const CodeComponent = ({ block, editor }) => {
  const { data } = block?.props;
  const onInputChange = (val, viewUpdate) => {
    editor.updateBlock(block, {
      props: { ...block.props, data: val },
    });
  };

  const updateData = (key, value) => {
    let newData = data;
    if (newData) {
      newData = { ...data, [key]: value };
    } else {
      newData = {
        [key]: value,
      };
    }
    // editor
  };

  return (
    <ReactCodeMirror
      placeholder={"Write your mermaid code here..."}
      style={{ width: "100%", resize: "vertical" }}
      extensions={[langs[data?.language ? data?.language : "javascript"]()]}
      value={data}
      theme={"dark"}
      editable={editor.isEditable}
      width="100%"
      height="200px"
      onChange={onInputChange}
    />
  );
};

export const CodeBlock = createReactBlockSpec({
  type: TYPE,
  propSchema: {
    ...defaultProps,
    data: {
      language: "javascript",
      code: "",
    },
  },
  containsInlineContent: false,
  render: CodeComponent,
});

export const insertCode = {
  name: "Code",
  group: "Other",
  execute: (editor) => {
    const block = editor.getTextCursorPosition().block;
    const blockIsEmpty = block.content.length === 0;

    // Updates current block to an Alert if it's empty, otherwise inserts a new
    // one below
    if (blockIsEmpty) {
      editor.updateBlock(block, { type: TYPE });
    } else {
      editor.insertBlocks(
        [
          {
            type: TYPE,
          },
        ],
        editor.getTextCursorPosition().block,
        "after"
      );
    }
  },
  aliases: ["procode"],
  icon: <MdCode />,
  hint: "Insert a code block.",
};
