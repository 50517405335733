import { InlineContent, createReactBlockSpec } from "@blocknote/react";
import { defaultProps } from "@blocknote/core";
import { Box, HStack } from "@chakra-ui/react";
// import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/auth";
import { useMemo, useRef } from "react";
import CustomTag from "../CustomTag";
import { BiSolidLabel } from "react-icons/bi";

const TYPE = "scope";

const Scope = ({ block, editor }) => {
  const { user } = useAuth();
  const currentEleRef = useRef();
  // const [isFocused, setFocused] = useState(false);
  const defaultLabels = useMemo(
    () => block?.props?.scope_labels?.map((l) => ({ label: l, value: l })),
    [block]
  );
  const isFocused = editor.getTextCursorPosition().block == block

  return (
    <Box onFocus={() => console.log("onFocus")} onBlur={() => console.log("onBlur")} ref={currentEleRef} w="full" py="3px">
      <HStack>
        <Box borderRight={"1px"} borderColor={"green.700"} borderRadius={"5px"} pr="3" w="full">
          <InlineContent
            data-placeholder="Testing placeholder  "
            style={{ padding: "3px 0px" }}
            placeholder="Enter scope content..."
          />
          {/* {isFocused && editor?.isEditable && <Box borderWidth={"1px"} borderColor={"green.200"} borderRadius={"5px"} mb="2">
            <CreatableSelect
              defaultValue={defaultLabels}
              value={block?.props?.scope_labels?.map((l) => ({
                label: l,
                value: l,
              }))}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderWidth: "0px",
                  background: "transparent",
                  backgroundColor: "transparent",
                }),
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
                DownChevron: () => null,
              }}
              isMulti
              placeholder="Enter scope labels"
              onChange={onChange}
            />
          </Box>} */}
        </Box>
        <CustomTag mr="1  " colorScheme={"green"}>
          Scope
        </CustomTag>
      </HStack>
    </Box>
  );
};

export const ScopeBlock = createReactBlockSpec({
  type: TYPE,
  propSchema: {
    ...defaultProps,
    scope_labels: ["test"],
  },
  containsInlineContent: true,
  render: Scope,
});

export const insertScope = {
  name: "Scope",
  group: "Other",
  execute: (editor) => {
    const block = editor.getTextCursorPosition().block;
    const blockIsEmpty = block.content.length === 0;

    // Updates current block to an Alert if it's empty, otherwise inserts a new
    // one below
    if (blockIsEmpty) {
      editor.updateBlock(block, { type: TYPE });
    } else {
      editor.insertBlocks(
        [
          {
            type: TYPE,
            backgroundColor: "gray",
            textColor: "blue",
          },
        ],
        editor.getTextCursorPosition().block,
        "after"
      );
    }
  },
  aliases: ["scope", "scope_label"],
  icon: <BiSolidLabel />,
  hint: "Insert a scope",
};
