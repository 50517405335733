import { createReactBlockSpec } from "@blocknote/react";
import { defaultProps } from "@blocknote/core";
import {
  Box,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import theme from "../../theme/theme";
import { useAuth } from "../../context/auth";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  createControl,
  updateControlDraft,
  publishControl,
} from "@defense-station/api-service";
import { showErrorToast } from "../../services/toast-service";
import CustomTag from "../CustomTag";
import { AiOutlineAlignLeft } from "react-icons/ai";

const TYPE = "control";

const Control = ({ block, editor }) => {
  const { user } = useAuth();
  console.log(block);
  const { data } = block.props;
  const previousBlockState = useRef();
  const [statement, setStatement] = useState("");
  const [selectedData, setSelectedData] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isChanged, setChanged] = useState(false);
  const [isPublishing, setPublishing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { onOpen, onClose } = useDisclosure();
  const [isEditMode, setEditMode] = useState(data?.control_id ? false : true);
  const isFocused = editor.getTextCursorPosition().block == block;

  const getBlockText = (blok = block) => {
    let text = "";
    if (blok.content?.length) {
      blok?.content?.map((paragraph) => {
        text += paragraph.text;
      });
    }
    return text;
  };

  useEffect(() => {
    if (previousBlockState.current) {
      if (getBlockText() != getBlockText(previousBlockState.current)) {
        setChanged(true);
      }
    }
    previousBlockState.current = block;
  }, [block]);


  useEffect(() => {
    if (!isFocused && isChanged) {
      console.log({ block });
      // updateBlockContentAndData(block?.props?.data?.control_data, data)
      if (block.props?.data?.control_data) {
        editor.updateBlock(block, {
          ...block,
          content: [
            {
              type: "text",
              text: block?.props?.data?.control_data,
              styles: {},
            },
          ],
        });
      }
    }
  }, [isFocused]);

  return (
    <ChakraProvider theme={theme}>
      <Box w="full" py="3px" cursor={"pointer"}>
        <HStack>
          <Box
            borderRight={"1px"}
            w={isFocused ? "full" : "max-content"}
            borderColor={"green.700"}
            borderRadius={"5px"}
            pr="3"
          >
            <Text>{data?.control_data}</Text>
          </Box>
          <CustomTag colorScheme={"blue"}>Control</CustomTag>
        </HStack>
      </Box>
    </ChakraProvider>
  );
};

export const ControlBlock = createReactBlockSpec({
  type: TYPE,
  propSchema: {
    ...defaultProps,
    data: {},
  },
  containsInlineContent: false,
  render: Control,
});

export const insertControl = {
  name: "Control",
  group: "Other",
  execute: (editor) => {
    const block = editor.getTextCursorPosition().block;
    const blockIsEmpty = block.content.length === 0;

    // Updates current block to an Alert if it's empty, otherwise inserts a new
    // one below
    if (blockIsEmpty) {
      editor.updateBlock(block, { type: TYPE });
    } else {
      editor.insertBlocks(
        [
          {
            type: TYPE,
            backgroundColor: "gray",
            textColor: "blue",
          },
        ],
        editor.getTextCursorPosition().block,
        "after"
      );
    }
  },
  aliases: ["control"],
  icon: <AiOutlineAlignLeft />,
  hint: "Insert a control",
};
