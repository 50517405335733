import { createContext, useContext, useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import {isLoggedIn} from '@defense-station/auth'
import {getUserProfile, getSettings} from '@defense-station/api-service'
import { showServerError } from "../services/toast-service";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(() =>
    isLoggedIn()
  );

  useEffect(() => {
    setLoggedIn(isLoggedIn())
    getUserProfile().then(user => {
        setUser(user?.data?.iam_v1_GetAuthInfo?.user)
    }).catch(showServerError)
  }, []);

  // Any user details we want can go here
  const value = {
    loggedIn,
    user,
    setUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => useContext(AuthContext);

const redirectToLogin = () => {
  navigateToUrl("/login");
};

const redirectToHome = () => {
  navigateToUrl("/employee-station");
};

const InnerLogin = ({ children }) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    return children;
  } else {
    redirectToLogin();
    return null;
  }
};

export const LoggedInRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogin>{children}</InnerLogin>
  </AuthProvider>
);

const InnerLogout = ({ children }) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    redirectToHome();
    return null;
  } else {
    return children;
  }
};

export const LoggedOutRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogout>{children}</InnerLogout>
  </AuthProvider>
);


export const LoggedOutRouteWithUser = ({children}) => {
  <AuthProvider>
    {children}
  </AuthProvider>
}