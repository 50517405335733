import {
  Icon,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Button,
  Thead,
  Tr,
  useColorModeValue,
  useBreakpointValue,
  Center,
  Spinner,
  Stack,
  SkeletonText,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheck, MdCheckCircle, MdOutlineCalendarToday } from "react-icons/md";
import Card from "../card/Card";
import LoadMoreComponent from "../custom-styled-components/LoadMoreComponent";

function PublishedDocumentsTable({onSeeAllClick, onView = (data) => null, isLoading, hasNext, fetchNext, isLoadingNext, ...rest}) {
  const { columnsData, tableData } = rest;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const buttonSize = useBreakpointValue({
    base: "xs",
    xl: "xs",
    "2xl": "xs"
  })
  return (
    <Card boxShadow="none" mb={{ base: "20px", xl: "0px" }}>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        {onSeeAllClick?<Flex
          align='center'
          justify='space-between'
          w='100%'
          px='10px'
          mb='20px'>
          <Text
            color={textColor}
            fontSize={{base: 'lg', xl: 'md', "2xl": "lg"}}
            fontWeight='700'
            lineHeight='100%'>
            Relevant Documents
          </Text>
          <Button variant='action' size={buttonSize} isDisabled={isLoading} onClick={onSeeAllClick}>See all</Button>
        </Flex>:null}
        {isLoading? 
        <Stack w="full">
          <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='3' />
        </Stack>
        :<Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px", xl: "10px", "2xl": "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Name") {
                      data = (
                        <Flex align='center'>
                          <Text
                            color={textColor}
                            fontSize={{base: 'sm', xl: "xs", "2xl": "sm"}}
                            fontWeight='600'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "Published On") {
                      data = (
                        <Text color={textColor} fontSize={{base: 'sm', xl: "xs", "2xl": "sm"}} fontWeight='600'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Effective From") {
                      data = (
                        <Text color={textColor} fontSize={{base: 'sm', xl: "xs", "2xl": "sm"}} fontWeight='600'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Acknowledged") {
                      data = (
                        cell.value?<Icon as={MdCheckCircle} boxSize={5} color={"brand.500"} />:null
                      );
                    } else if (cell.column.Header === " ") {
                      data = (
                        <Button onClick={() => onView(row?.original)} variant='action' size={buttonSize}>View</Button>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'
                        mt='20px !important'
                        py='10px !important'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>}
        {!isLoading && !data?.length && <Flex py="3" justify={"center"}>No document found.</Flex>}
        {(data?.length && !isLoading && hasNext)?<LoadMoreComponent
                  fetchNext={fetchNext}
                  hasNext={!isLast}
                  isLoading={isLoadingNext}
                />:null}
      </Flex>
    </Card>
  );
}

export default PublishedDocumentsTable;
