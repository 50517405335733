import { Flex, Spinner, VStack, Heading, Button } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  signWithSSO,
  isRedirectBackActionSet,
  removeRedirectionBackActionForLogin,
} from "@defense-station/auth";
import { useNavigate, Link } from "react-router-dom";
import { navigateToUrl } from "single-spa";

export default function HandleSSO() {
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isLinkBroken, setBrokenLink] = useState(false);
  const [serverError, setServerError] = useState(false);
  const handleLogin = useCallback(async () => {
    try {
      await signWithSSO({
        code: params.get("code"),
        state: params.get("state"),
        type: params.get("type"),
      });
      if (isRedirectBackActionSet()) {
        removeRedirectionBackActionForLogin();
        navigate(-1);
        return;
      }
      navigateToUrl("/employee-station");
    } catch (e) {
      console.error(e);
      setLoading(false);
      setServerError(true);
    }
  }, [navigate, params]);
  useEffect(() => {
    const state = params.get("state");
    const code = params.get("code");
    const type = params.get("type");
    if (!state || !code || !type) {
      setLoading(false);
      setBrokenLink(true);
    } else {
      setTimeout(() => handleLogin(), 2000);
    }
  }, [handleLogin, params]);
  return isLoading ? (
    <Flex h={"100vh"} justifyContent={"center"} alignItems="center">
      <Spinner />
    </Flex>
  ) : isLinkBroken ? (
    <Flex height={"95vh"} justifyContent={"center"} alignItems="center">
      <VStack>
        <Heading>Link Expired.</Heading>
      </VStack>
    </Flex>
  ) : serverError ? (
    <Flex height={"95vh"} justifyContent={"center"} alignItems="center">
      <VStack>
        <Heading>Something went wrong. Please try after sometime.</Heading>
        <Button as={Link} colorScheme="green" to="/login">
          Go to Login
        </Button>
      </VStack>
    </Flex>
  ) : (
    <div>HandleSSO</div>
  );
}
