import { Box } from "@chakra-ui/react";
import React from "react";

export default function CustomTag({ colorScheme, children, ...rest }) {
  return (
    <Box
      px="2"
      bg={`${colorScheme}.50`}
      borderRadius={"full"}
      color={`${colorScheme}.700`}
      fontSize={"sm"}
      borderColor={`${colorScheme}.600`}
      borderWidth={"1px"}
      {...rest}
    >
      {children}
    </Box>
  );
}
