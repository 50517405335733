export const tableColumnsMostVisited = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Published On",
    accessor: "updated_at",
  },
  // {
  //   Header: "Effective From",
  //   accessor: "created_at",
  // },
  {
    Header: "Acknowledged",
    accessor: "acknowledged",
  },
  {
    Header: " ",
  },
];
