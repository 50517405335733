import { Flex, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import CustomText from "./CustomText";

export default function LoadMoreComponent({
  hasNext,
  fetchNext,
  isLoading,
  ...rest
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Flex justifyContent={"center"}>
      {hasNext ? (
        <CustomButton
          isLoading={isLoading}
          colorScheme="brand"
          variant="outline"
          size="sm"
          onClick={fetchNext}
        >
          Load More
        </CustomButton>
      ) : (
        <CustomText color={textColor}>You have reached the end</CustomText>
      )}
    </Flex>
  );
}
