import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import "@blocknote/core/style.css";
import CustomFormLabel from "../../custom-styled-components/CustomFormLabel";
import "../../../assets/css/editor.css"
import avatar from "../../../assets/avatar.png";
import { format } from 'timeago.js'

const INIT_FORM_DATA = {
  name: "",
  data: "",
};

export default function RoleModal({
  data,
  onSubmit = (values) => null,
  onClose = () => null,
  isOpen = false,
  ...rest
}) {
  console.log(data, data?.data);
  const [animateOnFirstRender, setAnimateOnFirstRender] = useState(true);
  const [formData, setFormData] = useState(INIT_FORM_DATA);
  const editorData = useMemo(
    () => (data?.role_data ? JSON.parse(data?.role_data) : []),
    [data]
  );
  const editor = useBlockNote({
    editable: false,
    domAttributes: {
      editor: {
        class: "role-editor",
      }
    }
  });

  useEffect(() => {
    editor.slashMenu.emit("update", { referencePos: {} });
    console.log({data})
    if (data) {
      setFormData({
        name: data?.role_name,
        role_data: editorData,
      });
      if (data?.role_data) {
        editor.replaceBlocks(editor.topLevelBlocks, []);
        editor.replaceBlocks(editor.topLevelBlocks, editorData);
      } else {
        editor.replaceBlocks(editor.topLevelBlocks, []);
      }
    } else {
      setFormData(INIT_FORM_DATA);
      editor.replaceBlocks(editor.topLevelBlocks, []);
    }
  }, [data]);
  const borderColor = useColorModeValue(
    "brand.500 !important",
    "#111C44 !important"
  );

  return (
    <Modal
      onClose={onClose}
      size={"xl"}
      isOpen={isOpen}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent p="4">
        {/* <ModalHeader>{data?.name}</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalBody>
          <Center w="full" flexDir={"column"}>
          <Avatar
            position={"relative"}
            mx="auto"
            cursor={"pointer"}
            src={avatar}
            h="50px"
            w="50px"
            border="2px solid"
            borderColor={borderColor}
          />
            <Text fontWeight={"bold"} fontSize={"xl"}>{data?.name}</Text>
            <Text color='secondaryGray.600' fontSize={{base: 'sm', xl: "xs", "2xl": "sm"}} fontWeight='500'>
          {format(data?.created_at)}
        </Text>
            </Center>
        </ModalBody>
        <Box
            borderWidth={"1px"}
            borderColor={"secondaryGray.200"}
            borderRadius={"12px"}
            h={"100%"}
            pl="20px"
            mb="30px"
            minH="200px"
            >
            <BlockNoteView editor={editor} theme={"light"} />
          </Box>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
