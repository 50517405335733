import { Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import * as React from "react";
import { Sidebar } from "../components/sidebar/Sidebar";
import Footer from "../components/footer/FooterAdmin";

const MainLayout = ({ children }) => {
  const sidebarSize = useBreakpointValue({
    base: "240px",
    xl: "190px",
    "2xl": "240px",
  });
  const mainBoxWidth = useBreakpointValue({
    base: "100%",
    xl: `calc( 100% - ${sidebarSize} )`,
  });
  return (
    <Flex h="full" flexDirection="column">
      <Flex h="full" flex="1" overflow="hidden">
        <Sidebar
          width={sidebarSize}
          display={{
            base: "none",
            md: "flex",
          }}
        />
        <Box
          float="right"
          height="calc(100vh - 48px)"
          mb="20px"
          overflow="auto"
          position="relative"
          w={mainBoxWidth}
          maxWidth={mainBoxWidth}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box h={"full"} overflow="auto" pb="60px">
            {children}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MainLayout;
