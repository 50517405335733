import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { listPersonnelDocuments } from "@defense-station/api-service";
import { useAuth } from '../../../context/auth';
import { showErrorToast } from '../../../services/toast-service';
import { tableColumnsMostVisited } from './variables/tableColumnsMostVisited';
import PublishedDocumentsTable from '../MostVisitedTable';
const SORT_BY_KEYS = {
  STATUS: "STATUS",
  LAST_UPDATED: "LAST_UPDATED",
};

export default function DocumentsModal({ onView, onClose, isOpen, data, isLoadingNext, hasNext, fetchNext }) {
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(true);
  // const [data, setData] = useState([]);
  // const [filter, setFilter] = useState({
  //   search: "",
  //   statusArray: [],
  //   sortBy: SORT_BY_KEYS.LAST_UPDATED,
  // });
  // const [page, setPage] = useState();
  // const [pageSize, setPageSize] = useState(20);
  // const [initialized, setInitialized] = useState(false);
  // const [isLoadingNext, setLoadingNext] = useState(false);
  // const [isLast, setIsLast] = useState(false);
  // const [searching, setSearching] = useState(null);

  // const fetchData = (states) => {
  //   let args = {
  //     account_id: user.account_id,
  //     username: user.username,
  //     page_size: pageSize,
  //     last_cursor: page,
  //   };
  //   if (filter) {
  //     if (filter?.search) {
  //       args = {
  //         ...args,
  //         name: filter.search,
  //       };
  //     }
  //     if (filter?.sortBy) {
  //       args = {
  //         ...args,
  //         sort_by: filter.sortBy,
  //       };
  //     }
  //   }
  //   listPersonnelDocuments(args)
  //     .then((response) => {
  //       const loadedDocuments =
  //         response?.data?.policy_manager_v1_ListPublishedDocuments?.documents;
  //         if(loadedDocuments?.length < pageSize){
  //           setIsLast(true)
  //         }
  //         setPage(response?.data?.policy_manager_v1_ListPublishedDocuments.last_cursor)
  //       if (loadedDocuments?.length) {
  //         setData(
  //           data?.length && !states?.resetState
  //             ? [...data, ...loadedDocuments]
  //             : loadedDocuments
  //         );
  //       } else {
  //         setIsLast(true);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       showErrorToast(e?.message);
  //     })
  //     .finally(() => {
  //       setInitialized(true);
  //       setLoading(false);
  //       setSearching(false);
  //       setLoadingNext(false);
  //     });
  // };

  // const init = () => {
  //   fetchData();
  // }

  // useEffect(() => { 
  //   if(user) {
  //     init();
  //   }
  // }, [user])

  return (
    <Modal blockScrollOnMount={false} onClose={onClose} size={"6xl"} isOpen={isOpen} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Documents</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PublishedDocumentsTable onView={onView} tableData={data?data:[]}
            columnsData={tableColumnsMostVisited}
            fetchNext={fetchNext}
            hasNext={hasNext}
            isLoadingNext={isLoadingNext}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
