import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  getDocument,
  listDocumentSupervisors,
  acknowledgeDocument,
} from "@defense-station/api-service";
import { useAuth } from "../../../context/auth";
import {
  showErrorToast,
  showServerError,
  showSuccessToast,
} from "../../../services/toast-service";
import DocumentView from "./DocumentView";
import { APPROVER_TYPE, REVIEWER_TYPE } from "../../../constants/constants";
import { MdClose } from "react-icons/md";
import CustomButton from "../../custom-styled-components/CustomButton";
import Card from "../../card/Card";
import {
  useAnimate,
  useMotionValueEvent,
  useScroll,
  useSpring,
} from "framer-motion";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";
const SORT_BY_KEYS = {
  STATUS: "STATUS",
  LAST_UPDATED: "LAST_UPDATED",
};

export default function DocumentViewModal({
  document,
  isOpen,
  onClose,
  onAcknowledge = (document) => null,
}) {
  const ref = useRef();
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [isMounted, setMounted] = useState(false);
  const [isAcknowledging, setAcknowledging] = useState(false);
  const [data, setData] = useState();
  const [editorData, setEditorData] = useState();
  const progress = useSpring(0, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const [authors, setAuthors] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [isEndReached, setEndReached] = useState(false);
  const [isEditorReady, setEditorReady] = useState(false);
  const [buttonRef, animate] = useAnimate();
  const scrollContainer = window.document.getElementById("document-body");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const variants = {
    rotate: { rotate: [0, -30, 0], transition: { duration: 0.5 } },
    // You can do whatever you want here, if you just want it to stop completely use `rotate: 0`
    stop: { y: [0, -10, 0], transition: { repeat: Infinity, repeatDelay: 3 } },
  };
  const fetchFile = async (url) => {
    const doc = await fetch(url);
    setData((data) => ({
      ...data,
      s3_version: doc.headers.get("x-amz-version-id"),
    }));
    return doc.json();
  };

  const fetchSupervisors = async () => {
    const result = await listDocumentSupervisors({
      account_id: user?.account_id,
      username: user?.username,
      document_id: document?.document_id,
      filter_by: null,
    })
      .then((res) => {
        return res?.data?.policy_manager_v1_ListSupervisors?.supervisors;
      })
      .catch((e) => {
        console.log(e);
        showErrorToast("Unable to load authors.");
      });
    return result;
  };

  const fetchData = async () => {
    const result = await getDocument({
      username: user?.username,
      account_id: user?.account_id,
      document_id: document?.document_id,
    })
      .then((res) => {
        setData(res?.data?.policy_manager_v1_GetDocument?.document);
        // setDocument(res?.data?.policy_manager_v1_GetDocument?.document)
        // setFormData(res?.data?.policy_manager_v1_GetDocument?.document);
        return res?.data?.policy_manager_v1_GetDocument;
      })
      .catch((e) => {
        console.log(e);
        showErrorToast(e?.message);
      });
    return result;
  };

  const separateSupervisorsByType = (svisors) => {
    console.log({ svisors });
    let authorsList = [];
    let reviewersList = [];
    let approversList = [];
    svisors?.map((s) => {
      switch (s.type) {
        case "is_author_of":
          authorsList.push(s);
          break;
        case REVIEWER_TYPE:
          reviewersList.push(s);
          break;
        case APPROVER_TYPE:
          approversList.push(s);
          break;

        default:
          break;
      }
    });
    return {
      authors: authorsList,
      reviewers: reviewersList,
      approvers: approversList,
    };
  };

  const onAcknowledgeDocumentClick = async () => {
    try {
      setAcknowledging(true);
      await acknowledgeDocument({
        username: user?.username,
        account_id: user?.account_id,
        document_id: document?.document_id,
        personnel_id: user?.email,
      });
      onAcknowledge(document);
      showSuccessToast("Document has been acknowledged.");
    } catch (e) {
      showServerError(e);
    } finally {
      setAcknowledging(false);
    }
  };

  const init = async () => {
    try {
      const res = await fetchData();
      // if (res?.document?.version_map)
      // setVersionMap(
      //   res?.document?.version_map ? res?.document?.version_map : {}
      // );
      const supervsr = await fetchSupervisors();
      const supervisorsByType = separateSupervisorsByType(supervsr);
      // setSupervisors(supervsr);
      setAuthors(supervisorsByType.authors);
      setReviewers(supervisorsByType.reviewers);
      setApprovers(supervisorsByType.approvers);
      const doc = await fetchFile(res?.signed_urls[res?.document?.document_id]);
      setEditorData(doc);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && document) {
      init();
    }
    return () => {
      console.log("Unmounted");
    };
  }, [user, document]);

  const animateButton = async () => {
    if (!buttonRef.current) return;
    await animate(buttonRef.current, {
      scale: 1.3,
    });
    await animate(buttonRef.current, {
      scale: 1,
    });
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = ref.current;
    const p = ((scrollTop + clientHeight) / scrollHeight).toFixed(1);
    console.log(p, progress.get());
    if (p > progress.get()) progress.set(p);
    // Check if scrolled to the end
    if (scrollTop + clientHeight === scrollHeight && !isEndReached) {
      console.log("Scrolled to the end!");
      // Trigger your desired event or action here
      animateButton();
      setEndReached(true);
    }
  };

  useEffect(() => {
    // const scrollableDiv = window.document.getElementById("document-body");
    // if(isOpen && !isLoading){

    // Add scroll event listener to the div
    scrollContainer?.addEventListener("scroll", handleScroll);
    // }
    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainer, isEditorReady]);

  useEffect(() => {
    if (ref.current && isEditorReady) {
      console.log({ ref: ref });
      const { scrollTop, clientHeight, scrollHeight } = ref.current;
      console.log({ scrollTop, clientHeight, scrollHeight });
      console.log(clientHeight, scrollHeight);
      if (clientHeight >= scrollHeight && !isEndReached) {
        animateButton();
        progress.set(1);
        setEndReached(true);
      }
    }
  }, [ref, isEditorReady]);

  const onEditorReady = () => {
    setEditorReady(true);
  };

  const onCloseClick = () => {
    progress.set(0);
    setEndReached(false);
    if (onClose) onClose();
  };

  return (
    <Modal
      onClose={onCloseClick}
      size={"full"}
      isOpen={isOpen}
      scrollBehavior="inside"
    >
      <OverlayOne />
      <ModalContent
        h="full"
        bgColor={isLoading ? "#fff" : "transparent"}
        bg={isLoading ? "fff" : "transparent"}
      >
        {/* <ModalHeader>{document?.name}</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <Flex
          pos={"absolute"}
          top="0"
          w="full"
          justifyContent={"flex-end"}
          p="4"
        >
          <Button
            zIndex={9999}
            bgColor={"#fff"}
            w="max-content"
            onClick={onCloseClick}
            size={"sm"}
            colorScheme="brand"
            variant={"outline"}
            rightIcon={<MdClose />}
          >
            Close
          </Button>
        </Flex>
        <ModalBody ref={ref} id="document-body">
          <Center h={isLoading ? "100vh" : ""} flexDir={"column"} mb="50px">
            {isLoading ? (
              <Spinner />
            ) : editorData ? (
              <DocumentView
                title={document?.name}
                propertiesData={{
                  ...data,
                  authors: authors,
                  reviewers: reviewers,
                  approvers: approvers,
                }}
                editorData={editorData}
                onEditorReady={onEditorReady}
              />
            ) : null}
          </Center>
          {!isLoading &&
            createPortal(
              <Flex
                zIndex={9999}
                position="fixed"
                width={"full"}
                bgColor={"#fff"}
                bottom={"0"}
                flexDir={"column"}
              >
                <motion.div
                  style={{
                    backgroundColor: "#38A169",
                    scaleX: document?.acknowledged ? 1 : progress,
                    transformOrigin: "0%",
                    height: "5px",
                  }}
                ></motion.div>
                <HStack
                  p="20px"
                  w="full"
                  justify={"space-between"}
                  align={"center"}
                >
                  <Text>
                    Acknowledge that I have read and and understand this
                    document.
                  </Text>
                  <Button
                    cursor={"pointer"}
                    ref={buttonRef}
                    as={motion.div}
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.2 }}
                    isDisabled={!isEndReached || document?.acknowledged}
                    onClick={onAcknowledgeDocumentClick}
                    isLoading={isAcknowledging}
                    colorScheme="brand"
                    size="sm"
                  >
                    {document?.acknowledged ? "Acknowledged" : "Acknowledge"}
                  </Button>
                </HStack>
              </Flex>,
              window.document.body
            )}
        </ModalBody>
        {/* <ModalFooter>
        <Button onClick={onClose} colorScheme="brand">Close</Button>
      </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}
