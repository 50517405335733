// Chakra imports
import { Flex, Icon, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
// Custom components
import React, { useState } from "react";
import IconBox from "../../icons/IconBox";

export default function Controller(props) {
  const { icon, text, onValue, offValue, initial, activeColor, link } = props;
  const [activated, setActivated] = useState(initial);
  // Chakra Color Mode
  const completeBg = useColorModeValue(
    "white",
    "linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)"
  );
  const completeIcon = useColorModeValue("brand.500", "white");
  const completeShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "inset 0px 4px 4px rgba(255, 255, 255, 0.2)"
  );
  const completeColor = useColorModeValue("brand.500", "white");
  const incompleteBg = useColorModeValue(
    "white",
    "linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.03) 50.63%, rgba(255, 255, 255, 0) 100%)"
  );
  const incompleteIcon = useColorModeValue(
    "secondaryGray.600",
    "whiteAlpha.200"
  );
  const incompleteColor = useColorModeValue("secondaryGray.700", "white");
  const incompleteShadow = useColorModeValue(
    "inset 0px 18px 22px rgba(112, 144, 176, 0.1)",
    "inset 0px 4px 4px #0B1437"
  );
  const iconBoxSize = useBreakpointValue({
    base: "66px",
    xl: "46px",
    "2xl": "66px"
  })
  const iconSize = useBreakpointValue({
    base: "32px",
    xl: "24px",
    "2xl": "32px"
  })
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      cursor="pointer"
      role="group"
      onClick={function () {
        setActivated(!activated);
        if(link) window.open(link, "_blank");
      }}
    >
      <IconBox
        transition="0.2s linear"
        mb="10px"
        h={iconBoxSize}
        w={iconBoxSize}
        // bg={activated ? completeBg : incompleteBg}
        bg={completeBg}
        _groupHover={{
          bg: activeColor,
          boxShadow: completeShadow,
          borderColor: activeColor,
          boxShadow: "0px 6px 40px 1px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04)"
          // borderWidth: "1px"
        }}
        // boxShadow={activated ? completeShadow : incompleteShadow}
        boxShadow={completeShadow}
        icon={
          <Icon
            transition="0.2s linear"
            as={icon}
            _groupHover={{
              color: activeColor ? completeBg : completeIcon,
            }}
            // color={activated ? completeIcon : incompleteIcon}
            color={activeColor}
            h={iconSize}
            w={iconSize}
          />
        }
      />
      <Text
        transition="0.2s linear"
        fontSize={{base: "sm", xl: "xs", "2xl": "sm"}}
        fontWeight="500"
        color={activeColor}
        _groupHover={{
          color: activeColor ? activeColor : completeColor,
        }}
        me="4px"
      >
        {text}
      </Text>
    </Flex>
  );
}
