import {
  Route,
  Outlet,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import "./assets/css/App.css";
import MainLayout from "./layouts/MainLayout";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-toastify/dist/ReactToastify.css";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider, LoggedInRoute } from "./context/auth";
import Dashboard from "./pages/Dashboard";
import theme from "./theme/theme";
import { ToastContainer } from "react-toastify";
import Dashboard2 from "./pages/Dashboard2";
import NormalLayout from "./layouts/NormalLayout";
import HandleSSO from "./pages/HandleSSO";
console.log("Policy loaded");
export default function Root(props) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            <LoggedInRoute>
              <NormalLayout>
                <Outlet />
              </NormalLayout>
            </LoggedInRoute>
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route index path="/auth/sso/callback" element={<HandleSSO />} />
        </Route>
      </>
    ),
    {
      basename: "/employee-station",
    }
  );
  //Test
  return (
    <>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
        <ToastContainer />
      </ChakraProvider>
    </>
  );
}
