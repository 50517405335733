import { BlockNoteView, createReactBlockSpec, useBlockNote } from "@blocknote/react";
import { defaultProps } from "@blocknote/core";
import { Box, ChakraProvider, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { BiSolidUser } from "react-icons/bi";
import theme from "../../theme/theme";

const TYPE = "role";


const Role = ({ block }) => {
  useDisclosure();
  const roleEditor = useBlockNote({
    editable: false,
    initialContent: block?.props?.data?.role_data?JSON.parse(block?.props?.data?.role_data):[]
  })

  return (
    <ChakraProvider theme={theme}>
    <Box className="role-block" py={"3px"} w="full">
        <Box w="full">
            <HStack cursor={"pointer"} mb="4">
                <Text fontWeight={"bold"}>{block.props.data?.name}</Text>
                <Box px="2" bg="blue.50" borderRadius={"full"} color={"blue.700"} fontSize={"sm"} borderColor={"blue.600"} borderWidth={"1px"}>
                    Role
                </Box>
            </HStack>
           <BlockNoteView editor={roleEditor} theme={"light"} />
        </Box>
      </Box>
      </ChakraProvider>
  );
}

export const RoleBlock = createReactBlockSpec({
    type: TYPE,
    propSchema: {
      ...defaultProps,
      data: {},
    },
    containsInlineContent: false,
    render: Role,
});

export const insertRole = {
  name: "Role",
  group: "Other",
  execute: (editor) => {
    const block = editor.getTextCursorPosition().block;
    const blockIsEmpty = block.content.length === 0;

    // Updates current block to an Alert if it's empty, otherwise inserts a new
    // one below
    if (blockIsEmpty) {
      editor.updateBlock(block, { type: TYPE });
    } else {
      editor.insertBlocks(
        [
          {
            type: TYPE,
          },
        ],
        editor.getTextCursorPosition().block,
        "after"
      );
    }
  },
  aliases: ["role"],
  icon: <BiSolidUser />,
  hint: "Insert a Role",
};
