/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Button, Flex, HStack, Link, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { navigateToUrl } from "single-spa";

export function SidebarLink(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { to, name, icon, as } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return (location.pathname + location.search).includes(routeName);
  };
  const onClick = () => {
    navigateToUrl(to)
  }
  const textSize= useBreakpointValue({
    base: "md",
    xl: "xs",
    "2xl": "md",
  });
  const component = as!="button"?(<NavLink to={to}>
  <Flex
                align='center'
                justifyContent='space-between'
                w='100%'
                ps='17px'
                mb='0px'>
                <HStack
                  mb={{base: '6px', xl: '3px', "2xl": '6px'}}
                  spacing={
                    activeRoute(to) ? "22px" : "26px"
                  }>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(to)
                          ? activeIcon
                          : inactiveColor
                      }
                      me='12px'
                      mt={{base: '6px', xl: '3px', "2xl": '6px'}}>
                      {icon}
                    </Box>
                    <Text
                      me='auto'
                      fontSize={textSize}
                      color={
                        activeRoute(to)
                          ? activeColor
                          : "secondaryGray.600"
                      }
                      fontWeight='500'>
                      {name}
                    </Text>
                  </Flex>
                </HStack>
              </Flex>
    </NavLink>):
    (<Link textDecor={"none"} variant={"link"} onClick={onClick}>
    <Flex
                align='center'
                justifyContent='space-between'
                w='100%'
                ps='17px'
                mb='0px'>
                <HStack
                  mb={{base: '6px', xl: '3px', "2xl": '6px'}}
                  spacing={
                    activeRoute(to) ? "22px" : "26px"
                  }>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(to)
                          ? activeIcon
                          : inactiveColor
                      }
                      me='12px'
                      mt={{base: '6px', xl: '3px', "2xl": '6px'}}>
                      {icon}
                    </Box>
                    <Text
                      me='auto'
                      fontSize={textSize}
                      color={
                        activeRoute(to)
                          ? activeColor
                          : "secondaryGray.600"
                      }
                      fontWeight='500'>
                      {name}
                    </Text>
                  </Flex>
                </HStack>
              </Flex>
      </Link>)
  //  BRAND
  return (component)
}

export default SidebarLink;
