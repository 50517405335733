import React, { useRef, useEffect, memo, useMemo } from "react";
import mermaid from "mermaid";
import PropTypes from "prop-types";

const DEFAULT_CONFIG = {
  startOnLoad: true,
  theme: "forest",
  logLevel: "fatal",
  securityLevel: "strict",
  arrowMarkerAbsolute: false,
  flowchart: {
    htmlLabels: true,
    curve: "linear",
  },
  sequence: {
    diagramMarginX: 50,
    diagramMarginY: 10,
    actorMargin: 50,
    width: 150,
    height: 65,
    boxMargin: 10,
    boxTextMargin: 5,
    noteMargin: 10,
    messageMargin: 35,
    mirrorActors: true,
    bottomMarginAdj: 1,
    useMaxWidth: true,
    rightAngles: false,
    showSequenceNumbers: false,
  },
  gantt: {
    titleTopMargin: 25,
    barHeight: 20,
    barGap: 4,
    topPadding: 50,
    leftPadding: 75,
    gridLineStartPadding: 35,
    fontSize: 11,
    fontFamily: '"Open-Sans", "sans-serif"',
    numberSectionStyles: 4,
    axisFormat: "%Y-%m-%d",
  },
};

const Mermaid = ({ name, chart, config }) => {
  // Mermaid initilize its config
  mermaid.initialize({ ...DEFAULT_CONFIG, ...config });
    const containerRef = useRef();
  useEffect(() => {
    mermaid.contentLoaded();
  }, [config]);

  const id = useMemo(() => "mermaidBlock" + name?.substring(name.lastIndexOf("-") + 1), [name])
  

  const rerender = async () => {
    const isValid = await mermaid.parse(chart, {
      suppressErrors: true
    })
    if (!isValid) {
      if(containerRef.current) containerRef.current.innerHTML = "Invalid mermaid code!";
      return
    }
    console.log({name})
    const { svg } = await mermaid.render(id, chart);
    console.log({svg})
    if(svg) {
      if(containerRef.current) containerRef.current.innerHTML = svg;
    }
  }
  useEffect(() => {
    rerender();
  }, [chart]);

  if (!chart) return null;
  return (
    <div style={{color: "red", textAlign: "center"}} ref={containerRef}>
    </div>
  );
};

export default Mermaid;

Mermaid.propTypes = {
    name: PropTypes.string,
    chart: PropTypes.string,
    config: PropTypes.object,
};

Mermaid.defaultProps = {
    name: "Test",
    chart: `flowchart LR
    id[Write your mermaid code above to see charts here.]`,
    config: {},
};
