import { Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export default function CustomText({ children, ...rest }) {
  const textSize = useBreakpointValue({
    base: "md",
    xl: "xs",
    "2xl": "md",
  });
  return (
    <Text fontSize={textSize} {...rest}>
      {children}
    </Text>
  );
}
