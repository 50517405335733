import { Button, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export default function CustomButton({ children, ...rest }) {
  const buttonSize = useBreakpointValue({
    base: "md",
    xl: "sm",
    "2xl": "md",
  });
  return (
    <Button size={buttonSize} {...rest}>
      {children}
    </Button>
  );
}
