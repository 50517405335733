import React from "react";
import Card from "./card/Card";
import home from "../assets/mountain.jpg"
import { Box, HStack, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { MdModeNight } from "react-icons/md";
import { useAuth } from "../context/auth";
import { Player } from "@lottiefiles/react-lottie-player"
import animationData from "../assets/working.json";
const workloadPercentages = {
  Busy: "70-100%",
  Overwhelmed: "90-100%",
  Moderate: "40-60%",
  Light: "10-30%",
  Heavy: "70-100%",
  Manageable: "30-50%",
  Intense: "80-100%",
  Demanding: "60-90%",
  Chaotic: "80-100%",
  Balanced: "40-60%"
};

export default function Weather() {
  const { user } = useAuth();
  let mainText = useColorModeValue("navy.700", "white");
  return (
    <Card
      bgSize="cover"
      w=""
      minH={{ base: "300px", xl: "100%" }}
      bgImage={home}
    >
      {/* <Player
                    autoplay={true}
                    loop={true}
                    controls={false}
                    keepLastFrame={true}
                    src={animationData}
                    style={{ height: '200px', width: '200px' }}
                  ></Player> */}
      {user && <Box pos={"absolute"} right={6} bottom={6} maxW={"350px"} overflow={"hidden"}><Text fontSize={"2xl"} color={"#ffffff"} fontWeight={"bold"} isTruncated>{`Welcome Back, ${user?.first_name}!`}</Text></Box>}
    </Card>
  );
}
