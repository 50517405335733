import { Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function CardDescriptionText({ children, ...rest }) {
  const textColorSecondary = "gray.400";
  const textSize = useBreakpointValue({
    base: "sm",
    xl: "xs",
    "2xl": "sm",
  });
  return (
    <Text
      fontSize={textSize}
      color={textColorSecondary}
      fontWeight="bold"
      {...rest}
    >
      {children}
    </Text>
  );
}
